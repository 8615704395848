import React from "react";
import styled from "@emotion/styled";
import Container from "../Context/Container";
import color from "../../styles/colors";
import typography from "../../styles/typography";
import Section from "../Context/Section";
import FadeUp from "../Effects/FadeUp";

const Title = styled.h1`
    ${typography.h1};
    color: ${color("white")};
    width: 100%;
    text-align: center;
    display: inline-block;
    margin-bottom: 16px;

    @media screen and (min-width: 768px) {
        text-align: left;
        max-width: 802px;
        margin-bottom: 20px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 32px;
    }

    @media screen and (min-width: 1800px) {
        margin-bottom: 30px;
    }

    @media screen and (min-width: 2200px) {
        max-width: 1072px;
    }
`;

const PageIntro = styled.div`
    ${typography.homepageIntro};
    ${typography.RTESpacing};
    text-align: center;

    a{
        color: ${color("white")};
        transition: color 0.2s ease;
        text-decoration: none;

        &:hover{
            color: ${color("lite blue")};
        }
    }

    @media screen and (min-width: 768px) {
        max-width: 800px;
        text-align: left;
    }

    @media screen and (min-width: 1200px) {
        max-width: 887px;
    }

    @media screen and (min-width: 1800px) {
        max-width: 987px;
    }
`;

const VideoFrame = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    iframe{
        min-width: 100%;
        min-height: 100%;
        height: 56.25vw;
        width: 177.8vh;
        position: absolute;
    }

    img, div{
        width: 100%;
        height: 100%;
    }

    img{
        object-fit: cover;
        object-position: center center;
    }
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: ${color("dark")};
    opacity: 0.5;
    z-index: 200;
`;

const Hero = ({title, subtitle, image, theme}) => {
    const videoUrl = "https://player.vimeo.com/video/433468714?muted=1&autoplay=1&controls=0&dnt=1&loop=1&background=1&app_id=122963&h=034c01a476";

    return (
        <Section paddingS="134px 0 30px" paddingM="155px 0 66px" paddingL="171px 0 64px" paddingXL="171px 0 74px" paddingXXL="181px 0 124px" bg="dark" fg="white" minHeight="100vh" theme={theme} flexGrow="1">
            <Container extraBreakpoint={true} type="hero">
                <FadeUp time="2s">
                    <Title>
                        {title}
                    </Title>
                </FadeUp>
                <FadeUp time="2s" delay="0.4s">
                    <PageIntro dangerouslySetInnerHTML={{ __html: subtitle }} />
                </FadeUp>
            </Container>
            <VideoFrame>
                {image}
                <Overlay />
                <iframe
                    src={videoUrl}
                    width="426" height="240" frameBorder="0" allow="autoplay; fullscreen" allowFullScreen="" referrerPolicy="strict-origin"
                    title="L&#039;Atelier Hero Video" data-ready="true"/>
            </VideoFrame>
        </Section>
    );
};

export default Hero;
