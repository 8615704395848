import React from 'react';
import styled from "@emotion/styled";
import color from "../../styles/colors";
import typography from "../../styles/typography";
import Button from "../Elements/Button";
import GatsbyImage from "gatsby-image";
import { Link } from 'gatsby';

const Project = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    overflow:hidden;
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;

    &:last-of-type{
        margin-bottom: 0;
    }

    @media screen and (min-width: 768px) {
        flex-direction: row-reverse;
        align-items: stretch;
        margin-bottom: 40px;

        &:last-of-type{
            margin-bottom: 0;
        }
    }

    @media screen and (min-width: 1200px) {
        position: absolute;
        height: 100vh;
        margin-bottom: 0;
        /* ${ props => {
            const {position} = props;
            const z = 2- Math.abs(position)

            return `z-index:${z};`;
        }} */
    }
`
const Type = styled.p`
    ${typography.label};
    text-transform: uppercase;
    margin-bottom: 8px;
    position: relative;
    color: ${color("dark")};
    transition: transform .5s ease-out, opacity .5s;

    @media screen and (min-width: 1200px) {
        margin-bottom: 24px;
    }
`
const ProjectTitle = styled.h3`
    transition: transform .5s ease-out, opacity .5s;
    ${typography.h3Larger};
    margin-bottom: 16px;

    strong{
        font-weight: 600;
    }

    @media screen and (min-width: 768px) {
        margin-bottom: 16px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 32px;
    }
`
const ProjectDescription = styled.div`
    transition: transform .5s ease-out, opacity .5s;
    p{
        ${typography.body1};
    }
    margin-bottom: 0;

    @media screen and (min-width: 768px) {
        margin-bottom: -20px;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: 0;
    }
`;

const ProjectImage = styled.div`
    overflow:hidden;
    /* transition:transform .6s  cubic-bezier(0.785, 0.090, 0.270, 0.930); */
    height: 336px;

    @media screen and (min-width: 768px) {
        width: 46%;
        min-height: 356px;
        height: auto;
    }

    @media screen and (min-width: 1200px) {
        width: 50%;
        min-height: none;
        height: 100%;
        /* ${ props => {
            const {position} = props;
            return `transform: translateY(${position * 100}%);`;
        }} */
    }
`

const ImageWrap = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow:hidden;
    /* transition:transform .6s cubic-bezier(0.785, 0.090, 0.270, 0.930); */

    @media screen and (min-width: 1200px) {
        /* ${ props => {
            const {position} = props;
            return `transform: translate3d(0,${position * -50}%, 1px);`; // scale(${scale});
        }} */
    }
`

const Image = styled(GatsbyImage)`
    width:100%;
    height:100%;
`

const ImageParallax = styled.div`
    width: 100%;
    height: 110%;
    top:-5%;
    position: relative;
    /* transition:transform .2s; */
`

const ProjectDetails = styled.div`
    display: block;
    overflow:hidden;
    /* transition: opacity .7s, transform .7s ; */

    @media screen and (min-width: 768px) {
        width: 54%;
    }

    @media screen and (min-width: 1200px) {
        width: 50%;
        height: 100%;
        /* ${
            props =>{
                const { position } = props;
                const y = 10 * position;
                const opacity = 1- Math.abs(position);

                return `
                    opacity:${opacity};
                    transform:translateY(${y}%);
                `;
            }
        } */
    }

`

const DetailsWrap = styled.div`
    width: 100%;
    height: 100%;
    overflow:hidden;
    text-decoration: none;
    color: ${color("dark")};
    transition: color 0.2s ease;
    padding: 40px 0 0;
    background-color: ${color("white")};
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @media screen and (min-width: 768px) {
        padding: 30px 28px;
    }

    @media screen and (min-width: 1200px) {
        padding: 0 93px;
    }

    @media screen and (min-width: 1800px) {
        padding: 0 112px;
    }
`

const ProjectButton = styled.div`
    transition: transform .5s ease-out, opacity .5s;
`;

const ProjectComponent = ({image, type, title, subtitle, description, url, alt, linkText}) => {

    return (
        <Project>
            <ProjectImage className="imgOuter">
                <ImageWrap className="imgInner">
                    <ImageParallax className="parallax">
                        <Link to={url}>
                            <Image fluid={image} objectFit={"cover"} objectPosition={"center center"} alt={alt} />
                        </Link>
                    </ImageParallax>
                </ImageWrap>
            </ProjectImage>
            <ProjectDetails>
                <DetailsWrap className="details">
                    <Type className="details1">{type}</Type>
                    <ProjectTitle className="details2">
                        <strong>{title} -</strong> {subtitle}
                    </ProjectTitle>
                    <ProjectDescription dangerouslySetInnerHTML={{ __html: description }} className="details3" />
                    <ProjectButton className="details4">
                        <Button url={url} text={linkText} internal={true}  />
                    </ProjectButton>
                </DetailsWrap>
            </ProjectDetails>
        </Project>
    );
};

export default ProjectComponent;
