import React, { useRef, useEffect, Fragment } from 'react';
import styled from "@emotion/styled";
import color from "../../styles/colors";
// import { Link } from 'gatsby';
import Project from "./Project";
// import Container from "../Context/Container";
import typography from "../../styles/typography";
// import Section from "../Context/Section";
import { clamp, relativeProgress, easing, mod } from '../../util/math'

import { af as AF } from '@gladeye/af'

// const Intro = styled.div`
//     margin-bottom: 40px;

//     p{
//         &:last-of-type{
//             margin-bottom: 0;
//         }
//     }

//     @media screen and (min-width: 768px) {
//         margin-bottom: 56px;
//     }

//     @media screen and (min-width: 1200px) {
//         margin-bottom: 90px;
//     }

//     @media screen and (min-width: 1800px) {
//         margin-bottom: 100px;
//     }
// `;
// const Row = styled.div`
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: center;
//     margin-bottom: 17px;

//     a{
//         ${typography.link};
//         color: ${color("dark")};
//         display: flex;
//         flex-direction: row;
//         transition: color 0.2s ease;
//         white-space: nowrap;

//         &:hover{
//             color: ${color("dark blue")};
//         }
//     }

//     @media screen and (min-width: 768px) {
//         margin-bottom: 26px;
//     }

//     @media screen and (min-width: 1200px) {
//         align-items: flex-end;
//         margin-bottom: 34px;

//         a{
//             margin-bottom: 0.6em;
//         }
//     }

//     @media screen and (min-width: 1800px) {
//         margin-bottom: 28px;
//     }
// `;
// const Title = styled.h2`
//     ${typography.h2};
//     flex-grow: 1;
//     flex-basis: 0;
//     margin-bottom: 0;

//     @media screen and (min-width: 768px) {
//         line-height: 1.1;
//         margin-bottom: 0;
//     }

//     @media screen and (min-width: 1200px) {
//         line-height: 1.05;
//         margin-bottom: 0;
//     }

//     @media screen and (min-width: 1800px) {
//         margin-bottom: 0;
//     }
// `;

// const Standard = styled.div`
//     ${typography.standard};
//     p{
//         @media screen and (min-width: 1200px) {
//             max-width: 815px;
//         }

//         @media screen and (min-width: 1800px) {
//             max-width: 1100px;
//         }
//     }
// `;
const ProjectsWrapper = styled.div`
    width: 100%;
    position: relative;
    padding: 64px 20px;

    @media screen and (min-width: 600px) {
        padding: 64px 48px;
    }

    @media screen and (min-width: 768px) {
        padding: 64px 0 0;
    }

    @media screen and (min-width: 1200px) {
        padding: 0;
        height: ${ props => `${ props.total*100}vh` };
    }
`;

const ProjectsSticky = styled.div`
    top: 0;
    width: 100%;
    display: block;
    left:0;

    @media screen and (min-width: 1200px) {
        position: sticky;
        height: 100vh;
    }
`;



// const Dot = styled.div`
//     width: 20px;
//     height: 26px;
//     cursor: hand;
//     cursor: pointer;
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: center;

//     &:hover{
//         div{
//             background: ${color("dark blue")};
//         }
//     }
// `

// const Circle = styled.div`
//     width: 10px;
//     height: 10px;
//     border-radius:10px;
//     border: 1.5px solid ${color("dark blue")};
//     transition: background-color 0.2s ease;

//     ${
//         props => {
//             const { active } = props;
//             return active ? `background-color:${color("dark blue")};` : '' ;
//         }
//     }
// `

const ProjectsContainer = styled.div`
    position: relative;
    width: 100%;
    border: 3px solid ${color("white")};

    @media screen and (min-width: 1200px) {
        border-width: 4px;
    }
`

const ProjectsIntro = styled.div`
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    display: flex;
    align-items:center;
    justify-content:center;
    padding-bottom: 64px;
    margin-bottom: 64px;
    border-bottom: 1px solid ${color("grey")};
    text-align: left;

    @media screen and (min-width: 768px) {
        margin-left: auto;
        margin-right: auto;
        padding-left: 48px;
        padding-right: 48px;
        margin-bottom: 0;
        border-bottom: none;
        max-width: 1000px;
    }

    @media screen and (min-width: 1200px) {
        position: absolute;
        height: 100vh;
        margin: 0;
        padding: 0;
        border-bottom: none;
        max-width: none;
    }


    .details{
        text-align: left;
        ${typography.pageIntro};
        ${typography.RTESpacing};
        /* transition: transform .5s ease-out; */

        @media screen and (min-width: 768px) {
            max-width: 802px;
            text-align: center;
        }


        @media screen and (min-width: 2200px) {
            max-width: 1072px;
        }
    }

    .details1{
        transition: transform .5s ease-out, opacity .5s;
    }

    .details4{
        transition: transform .5s ease-out, opacity .5s;
    }

`



const Projects = ({title, intro, projects}) => {
    // const [projectNum, setProjectNum] = useState(0);
    const trackerRef = useRef();
    const projectParentRef = useRef()
    // const [keyboardActive, setKeyboardActive] = useState(false);



    const ProjectsList = projects.map((project, i) => {
        const position = i; // i- projectNum;

        if(project === null)  return null;

        return (
            <Project
                title={project.title}
                subtitle={project.subtitle}
                image={project.image}
                alt={project.alt}
                description={project.description}
                type={project.type}
                linkText="View this project"
                url={project.url}
                key={project.id}
                position={position}
            />
        )
    });





    useEffect( () => {
        const af = AF()
        const items = [];
        const children = Array.from(projectParentRef.current.children)
        let val = 0;
        let animVal = 0;

        // let nVal = 0;

        children.forEach(function(child, i){
            const imgOut = child.querySelector('.imgOuter') || document.createElement('div')
            const imgIn = child.querySelector('.imgInner') || document.createElement('div')
            const details = child.querySelector('.details')
            const details1 = child.querySelector('.details1') || document.createElement('div')
            const details2 = child.querySelector('.details2') || document.createElement('div')
            const details3 = child.querySelector('.details3') || document.createElement('div')
            const details4 = child.querySelector('.details4') || document.createElement('div')
            // console.log(details4)
            items.push({el:child, imgOut, imgIn, details, details1, details2, details3, details4})
        })


        const read = () =>{

            const length = items.length

            if(window.innerWidth < 1200) return;

            const {top, height} = trackerRef.current.getBoundingClientRect()
            val = top*-1 / (height-window.innerHeight);

            val *= length;

            const remainder = mod( val , 1 );

            val = Math.floor(val) + easing.inOutSine(remainder);

            if(val < 2) val *= 0.5;
            else val -= 1


            animVal += (val - animVal) * 0.2;
        }

        const write = () =>{

            const clamped = clamp(animVal, 0, projects.length)

            if(window.innerWidth < 1200) {

                items.forEach(function(item, i){
                    item.imgOut.style.transform = `none`
                    item.imgIn.style.transform = `none`
                    item.details.style.transform = `none`
                    item.details1.style.transform = `none`
                    item.details2.style.transform = `none`
                    item.details3.style.transform = `none`

                    item.details4.style.transform = `none`
                    item.imgIn.style.opacity = 1
                    item.details4.style.opacity = 1
                    item.details1.style.opacity = 1
                    item.details2.style.opacity = 1
                    item.details3.style.opacity = 1

                    item.details.style.opacity = 1
                })

                return;
            }
            items.forEach(function(item, i){

                const a = i === 0 ? animVal*.9 : animVal
                const c = i === 0 ? clamped*.9 : clamped

                let v = relativeProgress(c, i-1, i+1);
                let t = relativeProgress(a, i-1, i+1);

                v *= 2
                v -= 1

                let trigger = t > 0.3;

                // if( i === 0 ) trigger = true;

                if(Math.abs(v) < 0.5) item.el.style.zIndex = 2
                else item.el.style.zIndex = 1

                if(i=== 1 && v <= 0){
                    const tv = clamp(((v+1)-0.5)*2, 0, 1)
                    item.imgIn.style.transform = `scale(${2 - 1*tv})`
                    item.imgOut.style.transform = ` scale(${0.5 + 0.5*tv}) translate3d(0,${-v * 10}%,1px)`
                    item.imgIn.style.opacity = tv
                }else{
                    item.imgOut.style.transform = `translate3d(0,${-v * 100}%,1px)`
                    item.imgIn.style.transform = `translate3d(0,${+v * 50}%,1px)`
                }

                let opacity =  1 - relativeProgress(Math.abs(v), 0, (i === 0 ? 0.4 : 0.5))

                item.details.style.transform = `translate3d(0,${-v * 30}vh,1px)`
                item.details.style.opacity = opacity

                item.details1.style.transform =  `translate3d(0,${trigger ? 0 : 20}px,1px)`
                item.details1.style.opacity =  trigger ? 1 : 0;

                item.details2.style.transform =  `translate3d(0,${trigger ? 0 : 20}px,1px)`
                item.details2.style.transitionDelay =  trigger ? '0.2s' : '0s';
                item.details2.style.opacity =  trigger ? 1 : 0;

                item.details3.style.transform =  `translate3d(0,${trigger ? 0 : 20}px,1px)`
                item.details3.style.transitionDelay =  trigger ? '0.4s' : '0s';
                item.details3.style.opacity =  trigger ? 1 : 0;

                item.details4.style.transform =  `translate3d(0,${trigger ? 0 : 20}px,1px)`
                item.details4.style.transitionDelay =  trigger ? '.6s' : '0s';
                item.details4.style.opacity =  trigger ? 1 : 0;
            } )
        }

        af.addRead(read)
        af.addWrite(write)


        return () =>{
            af.removeRead(read)
            af.removeWrite(write)
        }

    },[projectParentRef, trackerRef])



    return (
        <Fragment>
            {/* <Section paddingS="64px 0 0" paddingM="72px 0 0" paddingL="105px 0 0" paddingXL="120px 0 0" paddingXXL="140px 0 0" bg="white" fg="dark" border={false}>
                <Container>
                    <Intro>
                        <Row>
                            <Title>
                                {title}
                            </Title>
                            <Link to="/projects">View all</Link>
                        </Row>
                        <Standard dangerouslySetInnerHTML={{ __html: intro }} />
                    </Intro>
                </Container>
            </Section> */}
            <ProjectsContainer>
                <ProjectsWrapper ref={trackerRef}  total={projects.length + 1}>

                    <ProjectsSticky>
                        <div ref={projectParentRef}>
                            <ProjectsIntro>
                                <div className="details">
                                    <h2 className="details1">{title}</h2>
                                    <br/>

                                    <div className="details4">
                                        <span dangerouslySetInnerHTML={{ __html: intro }} />
                                    </div>
                                </div>

                            </ProjectsIntro>
                            {ProjectsList}
                        </div>

                    </ProjectsSticky>
                </ProjectsWrapper>
            </ProjectsContainer>
        </Fragment>
    );
};

export default Projects;
