import React , {useState} from "react";

import GatsbyImage from "gatsby-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/Context/Header";
import Hero from "../components/General/Hero";
// import Intro from "../components/General/Intro";
import Projects from "../components/Landing/Projects";
import InsightsBlock from "../components/Insights/InsightsBlock";
import FeaturedBlock from "../components/News/FeaturedBlock";
import CTA from "../components/General/CTA";
import Footer from "../components/Context/Footer";

import { graphql } from "gatsby";
import usePreviewData from "../util/usePreviewData";

const LandingPage = ({ data: staticData }) => {
    const data = usePreviewData(staticData);
    const { name, tagline, hero_image_fallback, heading_projects, teaser_projects, heading_news, heading_promo, teaser_promo, button_cta, link_promo, project_1, project_2, project_3, heading_insights } = data.prismicHome.data;
    const theme = "light";
    const [ preventNav, setPreventNav ] = useState(false);
    
    const preventNavFn = (val) =>{
        setPreventNav(val)
    }
    
    const imagePlaceholder = data.file.childImageSharp.fluid;

    return (
        <Layout>
            <SEO title="Home" />
            <Header theme={theme} preventNav={preventNav}/>
            <Hero
                title={name.text}
                subtitle={tagline.html}
                image={<GatsbyImage fluid={hero_image_fallback.fluid} alt={hero_image_fallback.alt} loading={"eager"} />}
                theme={theme}
            />
            {/* <Intro
                content={tagline.html}
                theme={theme}
                homepage={true}
            /> */}
            <Projects
                preventNavFn={preventNavFn}
                theme={theme}
                title={heading_projects.text}
                intro={teaser_projects.html}
                projects={
                    [project_1, project_2, project_3].map((project) => {
                        if(project && project.document) {
                            return {
                                url: project.document.url,
                                image: project.document.data.listing_image.fluid,
                                alt: project.document.data.listing_image.alt,
                                description: project.document.data.summary.html,
                                type: project.document.data.type,
                                title: project.document.data.name.text,
                                subtitle: project.document.data.tagline,
                                id: project.document.id
                            }
                        }
                        return null;
                    })
                }
            />
            <InsightsBlock
                theme={theme}
                title={heading_insights.text}
                articles={
                    data.allPrismicInsight.nodes.map((article => {
                        return {
                            key: article.id,
                            url: article.url,
                            description: article.data.summary.html,
                            date: article.data.date,
                            author: article.data.author.document?.data.name.text,
                            title: article.data.title.text,
                            image: article.data.thumbnail?.fluid ?? imagePlaceholder,
                            alt: article.data.featured_image?.alt,
                            categories: article.data.categories,
                            type: null,
                            buttonText: "Read more"
                        }
                    }))}
                viewAll={true}
                bg="insights blue"
            />
            <FeaturedBlock
                theme={theme}
                title={heading_news.text}
                featured={
                    data.allPrismicNewsArticle.nodes.map((article => {
                        return {
                            key: article.id,
                            url: article.data.external_platform ? article.data.external_article_link.url : article.url,
                            description: article.data.summary.html,
                            date: article.data.date,
                            author: article.data.author,
                            title: article.data.name.text,
                            type: article.data.external_platform || null,
                            buttonText: `Read this article${article.data.external_platform ? ` on ${article.data.external_platform}` : ""}`
                        }
                    }))}
                viewAll={true}
                sectionSibling={true}
                bg="insights blue"
            />
            <CTA
                theme={theme}
                title={heading_promo.text}
                content={teaser_promo.html}
                buttonText={button_cta}
                buttonUrl={link_promo.url}
                buttonInternal={true}
            />
            <Footer theme={theme} />
        </Layout>
    );
};

export const pageQuery = graphql`
    {
        prismicHome {
            data {
                button_cta
                heading_news {
                    text
                }
                heading_insights {
                    text
                }
                heading_projects {
                    text
                }
                heading_promo {
                    text
                }
                link_promo {
                    url
                    target
                }
                name {
                    text
                }
                tagline {
                    html
                }
                hero_image_fallback {
                    alt
                    fluid(maxWidth: 1400) {
                        ...GatsbyPrismicImageFluid
                    }
                }
                teaser_projects {
                    html
                }
                teaser_promo {
                    html
                }
                project_1 {
                    document {
                        ... on PrismicProject {
                            id
                            url
                            data {
                                listing_image {
                                    alt
                                    fluid(maxWidth: 900) {
                                        ...GatsbyPrismicImageFluid
                                    }
                                }
                                name {
                                    text
                                }
                                summary {
                                    html
                                }
                                tagline
                                type
                            }
                        }
                    }
                }
                project_2 {
                    document {
                        ... on PrismicProject {
                            id
                            url
                            data {
                                listing_image {
                                    alt
                                    fluid(maxWidth: 900) {
                                        ...GatsbyPrismicImageFluid
                                    }
                                }
                                name {
                                    text
                                }
                                summary {
                                    html
                                }
                                tagline
                                type
                            }
                        }
                    }
                }
                project_3 {
                    document {
                        ... on PrismicProject {
                            id
                            url
                            data {
                                listing_image {
                                    alt
                                    fluid(maxWidth: 900) {
                                        ...GatsbyPrismicImageFluid
                                    }
                                }
                                name {
                                    text
                                }
                                summary {
                                    html
                                }
                                tagline
                                type
                            }
                        }
                    }
                }
            }
        }
        allPrismicNewsArticle(sort: {fields: data___date, order: DESC}, limit: 3) {
            nodes {
                id
                uid
                url
                data {
                    author
                    date(formatString: "MMM D YYYY")
                    summary {
                        html
                    }
                    external_platform
                    external_article_link {
                        url
                        target
                        link_type
                    }
                    name {
                        text
                    }
                }
            }
        }
        allPrismicInsight(sort: {fields: data___date, order: DESC}, limit: 3) {
            nodes {
                id
                uid
                url
                data {
                    title {
                        text
                    }
                    summary {
                        html
                    }
                    date(formatString: "MMM D YYYY")
                    thumbnail {
                        alt
                        fluid(maxWidth: 2250) {
                            base64
                            aspectRatio
                            src
                            srcSet
                            sizes
                        }
                    }
                    author {
                        document {
                            ... on PrismicAuthor {
                                data {
                                    name {
                                        text
                                    }
                                }
                            }
                        }
                    }
                    categories {
                        category {
                            document {
                                ... on PrismicCategory {
                                    data {
                                        title
                                    }
                                    uid
                                }
                            }
                        }
                    }
                }
            }
        }
        file(name: {eq: "placeholder-insights-image"}) {
            childImageSharp {
                fluid(maxWidth: 2250) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
    }`;

export default LandingPage;
